import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import Icon from "../Icon";
import { twMerge } from "tailwind-merge";

const getYoutubeIframeURL = (src: string) => {
  if (src.includes("youtu.be")) {
    const videoID = src.split("/").pop();
    return `https://www.youtube.com/embed/${videoID}`;
  }

  if (src.includes("youtube.com")) {
    const url = new URLSearchParams(src.split("?")[1]);
    const videoID = url.get("v");
    return `https://www.youtube.com/embed/${videoID}`;
  }

  return "";
};

const getVimeoIframeURL = (src: string, autoPlay?: boolean) => {
  const videoID = src.split("/").pop()?.split("?")[0] || "";

  return `https://player.vimeo.com/video/${videoID}?controls=1&transparent=1&autoplay=${autoPlay ? 1 : 0}`;
};

const getIframeURL = (src: string, autoPlay?: boolean) => {
  if (src.includes("vimeo")) {
    return getVimeoIframeURL(src, autoPlay);
  }

  if (src.includes("youtu.be") || src.includes("youtube")) {
    return getYoutubeIframeURL(src);
  }

  return undefined;
};

interface VideoProps {
  src: string;
  thumbnailFallback?: string;
  className?: HTMLAttributes<HTMLDivElement>["className"];
}

export const Video: FC<VideoProps> = ({
  src,
  thumbnailFallback,
  className,
}) => {
  const iframeSrc = getIframeURL(src, true);
  const [showThumb, setShowThumb] = useState(true);
  const [thumbNailUrl, seThumbNailUrl] = useState("");

  useEffect(() => {
    fetch(
      `https://vimeo.com/api/v2/video/${src.split("/").pop()?.split("?")[0]}.json`
    )
      .then((response) => response.json())
      .then((response) => {
        seThumbNailUrl(response[0].thumbnail_large);
      })
      .catch(() => {
        seThumbNailUrl(thumbnailFallback || "");
      });
  }, [src]);

  if (!iframeSrc) {
    return <React.Fragment />;
  }

  if (src.includes("vimeo")) {
    if (showThumb) {
      return (
        <div className="relative w-full h-full">
          {thumbNailUrl && (
            <img
              src={thumbNailUrl}
              alt="video thumbnail"
              className={twMerge("w-full h-full object-cover", className)}
            />
          )}
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowThumb(false);
            }}
            className={twMerge(
              "absolute inset-0 flex justify-center items-center bg-gray-600 bg-opacity-50",
              className
            )}
          >
            <Icon icon="play" size="xl" className="text-white" />
          </button>
        </div>
      );
    }

    return (
      <iframe
        src={iframeSrc}
        className="w-full h-full border-0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title="video"
      />
    );
  }

  return (
    <iframe
      src={iframeSrc}
      className="w-full h-full border-0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      title="video"
    />
  );
};
