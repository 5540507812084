import React, { FC, useEffect, useRef, useState } from "react";
import { Trans } from "@helpers/translate";
import { ArrowPrev } from "../Icon/icons/ArrowPrev";
import ModalAnimation from "../ModalAnimation/ModalAnimation";
import { ArrowNext } from "../Icon/icons/ArrowNext";
import { Close } from "../Icon/icons/Close";
import { Video } from "../Video";
import { Image } from "../Image";
import { Swiper, SwiperClass, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import ReactDOM from "react-dom";
import { IGalleryItemProps } from ".";

interface IModalProps {
  items: IGalleryItemProps[];
  currentIndex: number;
  onRequestClose: () => void;
  visible: boolean;
}

type IGalleryItem = {
  currentImageCarroseulIndex: number;
} & IGalleryItemProps;

const GalleryItem: FC<IGalleryItem> = (item) => {
  return (
    <div className="relative shadow-md rounded-lg w-full h-75-vh">
      {item.type === "image" && (
        <Image
          src={item.src}
          alt={item.alt || ""}
          className="w-full object-contain h-75-vh"
          fill={true}
          activeCache={true}
        />
      )}

      {item.type === "video" && (
        <div className="flex w-full h-full justify-center align-middle">
          <div className="flex w-10/12 h-1/3 md:h-full my-auto">
            {/* stop video when slide to other item */}
            {item.currentImageCarroseulIndex === 0 && (
              <Video
                src={item.src}
                thumbnailFallback={item.thumbnailFallback}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const ModalBigPicture: FC<IModalProps> = ({
  items,
  currentIndex,
  onRequestClose,
  visible,
}) => {
  const bigImageRef = useRef<HTMLDivElement>(null);
  const [currentImageCarroseulIndex, setCurrentImageCarroseulIndex] =
    useState(currentIndex);
  const [modalCarrouselVisible, setModalCarrouselVisible] = useState(false);

  const handleOpenImageBigSize = (index: number) => {
    setModalCarrouselVisible(true);
    setCurrentImageCarroseulIndex(index);
  };

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
    if (visible) {
      handleOpenImageBigSize(currentIndex);
    } else {
      setModalCarrouselVisible(false);
    }
  }, [visible]);

  const CarousselActions = () => {
    const swiper = useSwiper();

    const onClickChangeImage = (order: "prev" | "next") => {
      if (currentImageCarroseulIndex === 0 && order === "prev") {
        return;
      }
      if (currentImageCarroseulIndex >= items.length - 1 && order === "next") {
        return;
      }
      bigImageRef.current?.classList.replace("opacity-1", "opacity-0");
      setCurrentImageCarroseulIndex(swiper.realIndex);
      if (order === "next") {
        swiper.slideNext();
      } else {
        swiper.slidePrev();
      }
      setTimeout(() => {
        bigImageRef.current?.classList.replace("opacity-0", "opacity-1");
      }, 200);
    };

    const handleKey = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onRequestClose();
      }

      if (e.key === "ArrowLeft") {
        onClickChangeImage("prev");
      }
      if (e.key === "ArrowRight") {
        onClickChangeImage("next");
      }
    };

    useEffect(() => {
      document.addEventListener("keydown", handleKey, false);

      return () => {
        document.removeEventListener("keydown", handleKey, false);
      };
    }, []);

    return (
      <div>
        <div className="hidden md:flex items-center h-full absolute left-8 z-10 top-0">
          <button
            className={`flex rounded-full bg-white p-2 ${
              currentImageCarroseulIndex === 0 ? "opacity-50" : "opacity-100"
            }`}
            onClick={() => onClickChangeImage("prev")}
          >
            <ArrowPrev className="w-8 h-8 text-black" />
          </button>
        </div>
        <div className="hidden md:flex items-center h-full absolute right-8 z-10 top-0">
          <button
            className={`flex rounded-full p-2 bg-white ${
              currentImageCarroseulIndex === items.length - 1
                ? "opacity-50"
                : "opacity-100"
            }`}
            onClick={() => onClickChangeImage("next")}
          >
            <ArrowNext className="w-8 h-8 text-black" />
          </button>
        </div>
      </div>
    );
  };

  if (!isClient) return null;

  return ReactDOM.createPortal(
    <ModalAnimation visible={modalCarrouselVisible}>
      <div className="flex flex-col bg-black h-full relative">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <button
              onClick={() => onRequestClose()}
              className="flex flex-row px-4 py-2 m-8 hover:bg-gray-800 rounded-full items-center"
            >
              <Close className="w-6 h-6 text-white mb-0.5" />
              <p className="text-white ml-1 font-normal text-sm">
                <Trans>Close</Trans>
              </p>
            </button>
            <div className="flex absolute left-1/2 top-8 flex-row items-center mx-auto mb-8">
              <p className="text-white ">
                {currentImageCarroseulIndex + 1}/{items.length}
              </p>
            </div>
          </div>
        </div>

        <div className="flex w-full h-full items-center px-4 max-h-75-vh my-auto">
          <div className="flex w-full h-5/6 mb-24 justify-center items-center opacity-1 transition-opacity duration-200">
            <div className="flex w-full h-75vh align-middle relative">
              <div
                ref={bigImageRef}
                className="w-full h-full opacity-1 transition-opacity duration-200"
              >
                {currentIndex >= 0 && (
                  <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    slidesPerView={1}
                    loop={false}
                    autoplay={false}
                    initialSlide={currentIndex}
                    onSlideChangeTransitionEnd={(e: SwiperClass) => {
                      setCurrentImageCarroseulIndex(e.realIndex);
                    }}
                  >
                    <CarousselActions />
                    {items.map((item, index) => (
                      <SwiperSlide key={index}>
                        <GalleryItem
                          {...item}
                          currentImageCarroseulIndex={
                            currentImageCarroseulIndex
                          }
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </div>
            </div>
          </div>
          {items[currentImageCarroseulIndex]?.caption && (
            <div className="absolute bottom-0 w-full bg-gray-800 bg-opacity-50 px-4 py-2 text-white truncate text-center">
              <div className="max-h-12 overflow-hidden overflow-ellipsis">
                {items[currentImageCarroseulIndex]?.caption}
              </div>
            </div>
          )}
        </div>
      </div>
    </ModalAnimation>,
    document.body
  );
};

export default ModalBigPicture;
